<template>
	<GlobXpanel :noShadow="true"  :avoidHR="true" :isCRUD="true">
		<GlobSpinner :isLoading="isLoading"/>
		<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.general')">
            <GlobRow>
				<GlobCol :medium="5" v-if="userStore.UserGroup == 'SuperAdmin'">
					<label>
						{{$t('administration.user.fields.customer')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'virtualselect'"
						:placeholder="$t('administration.user.fields.customer')"
						v-model="worker.form.CustomerId"
						:data="customers"
						@input="customerChanged()"
						/>
					</label>
				</GlobCol>
			 </GlobRow>
			<GlobRow>
				<GlobCol :medium="5">
                    <label>
						{{$t('administration.worker.fields.name')}}*
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.worker.fields.name')"
						v-model="worker.form.Name"
						/>
					</label>
                </GlobCol>

                <GlobCol :medium="5">
                    <label>
						{{$t('administration.worker.fields.description')}}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t('administration.worker.fields.description')"
						v-model="worker.form.Description"
						/>
					</label>
                </GlobCol>
            </GlobRow>

            <GlobRow>
				<GlobCol :medium="5">
                    <label>
						{{$t('administration.worker.fields.worker_type')}}
						<InputDynamic
						:fullWidth="true"
						:type="'virtualselect'"
						:data="workerTypeList"
						:placeholder="$t('administration.worker.fields.worker_type')"
						v-model="worker.form.WorkerType"
						/>
					</label>
                </GlobCol>

                <GlobCol :medium="5" v-if="showWorkerPath">
                    <label>
						{{ $t(workerPathLabel) }}
						<InputDynamic
						:fullWidth="true"
						:type="'text'"
						:placeholder="$t(workerPathLabel)"
						v-model="worker.form.WorkerPath"
						/>
					</label>
                </GlobCol>

				<!-- Show fields needed for DB worker type -> Start -->
				<template v-if="worker.form.WorkerType == 'database'">
					<GlobCol :medium="5">
						<label>
							{{ $t('administration.worker.fields.db_type') }}*
							<InputDynamic
							:fullWidth="true"
							:type="'virtualselect'"
							:data="dbTypes"
							:placeholder="$t('administration.worker.fields.db_type')"
							v-model="worker.form.WorkerTypeSettings.DBType"
							/>
						</label>
					</GlobCol>
					<GlobCol :medium="5">
							<label>
								{{ $t('administration.worker.fields.server') }}*
								<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.worker.fields.server')"
								v-model="worker.form.WorkerTypeSettings.Server"
								/>
							</label>
					</GlobCol>
					<GlobCol :medium="5">
						<label>
							{{ $t('administration.worker.fields.db_name') }}*
							<InputDynamic
							:fullWidth="true"
							:type="'text'"
							:placeholder="$t('administration.worker.fields.db_name')"
							v-model="worker.form.WorkerTypeSettings.DBName"
							/>
						</label>
					</GlobCol>
					<GlobCol :medium="5">
						<label>
							{{ $t('administration.worker.fields.user_name') }}*
							<InputDynamic
							:fullWidth="true"
							:type="'text'"
							:placeholder="$t('administration.worker.fields.user_name')"
							v-model="worker.form.WorkerTypeSettings.Username"
							/>
						</label>
					</GlobCol>
					<GlobCol :medium="5">
						<label>
							{{ $t('administration.worker.fields.password') }}*
							<InputDynamic
							:fullWidth="true"
							:type="'password'"
							:placeholder="$t('administration.worker.fields.password')"
							v-model="worker.form.WorkerTypeSettings.Password"
							/>
						</label>
					</GlobCol>
				</template>
				<!-- Show fields needed for DB worker type -> End -->
                    
            </GlobRow>

            <GlobRow>
				<GlobCol :medium="5">
                    <label>
						{{$t('administration.worker.fields.timeout')}}
						<InputDynamic
						:fullWidth="true"
						:type="'number'"
						:placeholder="$t('administration.worker.fields.timeout')"
						v-model="worker.form.Timeout"
						/>
					</label>
                </GlobCol>

                <GlobCol :medium="5">
                    <label>
						{{$t('administration.worker.fields.alert_users')}}
						<InputDynamic
						:fullWidth="true"
						:type="'multiselect'"
						:data="workerAlertUserList"
						:placeholder="$t('administration.worker.fields.alert_users')"
						v-model="worker.form.AlertUsers"
						/>
					</label>
                </GlobCol>
            </GlobRow>

		</GlobXpanel>

		<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.worker.alert_on_failed_contact')">

			<GlobRow>
				<GlobCol :medium="3">
                    <label>
						{{$t('administration.worker.fields.alert_by_sms')}}
						<InputDynamic
						:fullWidth="true"
						:type="'checkbox'"
						:placeholder="$t('administration.worker.fields.alert_by_sms')"
						v-model="worker.form.AlertBySms"
						/>
					</label>
                </GlobCol>
				<GlobCol :medium="3">
                    <label>
						{{$t('administration.worker.fields.alert_by_email')}}
						<InputDynamic
						:fullWidth="true"
						:type="'checkbox'"
						:placeholder="$t('administration.worker.fields.alert_by_email')"
						v-model="worker.form.AlertByMail"
						/>
					</label>
                </GlobCol>
				<GlobCol :medium="3">
	                    <label>
							{{ $t('administration.worker.fields.alert_by_notification') }}
							<InputDynamic
							:fullWidth="true"
							:type="'checkbox'"
							:placeholder="$t('administration.worker.fields.alert_by_notification')"
							v-model="worker.form.AlertByNotification"
							/>
						</label>
					</GlobCol>
					<GlobCol :medium="3">
	                    <label>
							{{ $t('administration.worker.fields.alert_on_server_error') }}
							<InputDynamic
							:fullWidth="true"
							:type="'checkbox'"
							:placeholder="$t('administration.worker.fields.alert_on_server_error')"
							v-model="worker.form.AlertOnServerError"
							/>
						</label>
	                </GlobCol>
	                
				
			</GlobRow>

			<GlobRow>
				<GlobCol :medium="12">
					<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
					<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
				</GlobCol>
			</GlobRow>
		</GlobXpanel>
	</GlobXpanel>
</template>

<script>
import {onMounted, reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/Worker/common.js";
import { useStore } from 'vuex'

export default {
  props: ['customers'],
  setup(props, {emit}) {

	const store = useStore();
	const userStore = computed(() => store.state.USER);

    const { tm } = useI18n();
	const endpoint = computed(() => api.workers);
	const userEndpoint = computed(() => api.users);
	const api = useApi();
	const router = useRouter();
	const workerTypeList = reactive([{
		name: 'Script',
		value: 'script'
	},{
		name: 'Server',
		value: 'server',
	},{
		name: 'Webpage',
		value: 'webpage',
		},
	 {
		name: 'Database',
		value: 'database',
		},
		{
		name: 'Worker',
		value: 'worker'
	}]);

	const dbTypes = reactive([{
			name: 'MSQL',
			value: 'sqlsrv'
		}, {
			name: 'MySQL',
			value: 'mysql',
		}, {
			name: 'PostgreSQL',
			value: 'pgsql',
	}]);

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);
	const workerAlertUsers = ref([]);
	const showWorkerPath = computed(() => {
		return worker.form.WorkerType != 'script' && worker.form.WorkerType != 'worker'  && worker.form.WorkerType != "database" && worker.form.WorkerType != "";
	});
	const workerPathLabel = computed(() => {
		if (worker.form.WorkerType == 'webpage') {
			return 'administration.worker.fields.worker_server_webpage';
		} else {
			return 'administration.worker.fields.worker_server_script';
		}
	});
	const workerAlertUserList = computed(() => {
		return workerAlertUsers.value.map((item) => {
			return { 
				name: `${item.Name} ${item.LastName}`, 
				value: item.Id 
			};
		});
	});

	const worker = reactive({
		form: {
			CustomerId: "",
			Name: "",
            Description: "",
            LastCommunication: "",
			CurrentStatus: "",
			WorkerId: "",
			WorkerKey: "",
			WorkerType: "",
			WorkerServer:"",
			Timeout:"",
			AlertUsers: "",
			WorkerTypeSettings: {}
		},
	});


	onMounted(()=> {
		loadUsers();
	})

	const customerChanged = () => {
		worker.form.AlertUsers = [];
		loadUsers();
	}

    const save = () => {
		
		errorMessage.value = validateForm(worker.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.create(worker.form).then((res) => {
			isLoading.value = false;
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['worker']['create_success']
					},
					"success",
					false
				);
				emit('created')
				emit('close')
			}else{
				for(let errorField in res.errors){
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}

			
		});
    };

	const loadUsers = () => {
		let data;

		if(userStore.value.UserGroup == 'SuperAdmin')
		{
			if(!worker.form.CustomerId){
				workerAlertUsers.value = [];
				return false;
			}
			data = {
				'customer_id': worker.form.CustomerId
			}
		}

		userEndpoint.value.get(data)
			.then((res)=>{
				workerAlertUsers.value = res.data;
			});
	}
	const workerSettingsAdded = computed(() => {
		  let flag = true;
		  if (worker.form.WorkerType == 'database') {
			  if (!worker.form.WorkerTypeSettings.DBType || 
				  !worker.form.WorkerTypeSettings.Server ||
				  !worker.form.WorkerTypeSettings.DBName || 
				  !worker.form.WorkerTypeSettings.Username || 
				  !worker.form.WorkerTypeSettings.Password) {
					flag = false;
				  }
		  }
		  return flag;
	})

	const isSaveButtonDisabled = computed(() => {
		return !worker.form.Name ||
			(userStore.value.UserGroup == 'SuperAdmin' && !worker.form.CustomerId) ||
			!workerSettingsAdded.value
			? true : false
	})


    return {
      save,
	  errorMessage,
	  worker,
	  isLoading,
	  isSaveButtonDisabled,
	  workerAlertUserList,
	  workerTypeList,
	  showWorkerPath,
	  workerPathLabel,
	  userStore,
		customerChanged,
	  dbTypes
    };
  },
};
</script>

<style lang="scss" scoped>
.ips-xpanel {
	background: none !important;
}
</style>