<template>
  <div class="input-container">
    <select class="kits-input" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" style="padding: .5rem 1rem;">
      <option class="kits-input::placeholder" :value="null" >{{ placeholder }}</option>
      <option v-for="item in data" :value="item.value ? item.value : item.name" :key="'select-option-'+item.value">{{
        item.name ? item.name : item.value
      }}</option>
    </select>
    <i
      class="fa fa-times"
      v-if="modelValue != null && showReset"
      @click="$emit('update:modelValue', null)"
    />
  </div>
</template>

<script>
import { props } from './common';
export default {
  props: props,
};
</script>

<style lang="scss" scoped>
	select {
		font-size: .8rem;
	}
</style>
