import { createStore } from 'vuex'
import { createApi } from '@/plugins/api'
import { generateId } from '@/services/utils'
import definedModules from './modules';
const api = createApi();

export default createStore({
	modules: {
		...definedModules
	},
	state: {
		AUTHENTICATED: false,
		USER: {},
		STATUSES: ['hej'],
		SHOPPING_BASKET: [],
		DIFFANALYS: [],
		DATABASE_VALUES: [],
		RESERVED_ITEMS: [],
		CURRENT_PRODPLAN: [],
		NOTIFICATIONS: []
	},
	mutations: {
		SET_SHOPPING_BASKET(state, payload) {
			state.SHOPPING_BASKET = payload;
		},
		SET_DIFFANALYS(state, data) {
			state.DIFFANALYS = data;
		},
		SET_USER(state, user) {
			state.USER = user,
				state.AUTHENTICATED = true
		},
		SET_CURRENT_PRODPLAN(state, data) {
			state.CURRENT_PRODPLAN = data;
		},
		SET_DATABASE_VALUES(state, values) {
			state.DATABASE_VALUES = values;
		},
		SET_RESERVED_ITEMS(state, payload) {
			state.RESERVED_ITEMS = payload;
		},
		SET_STATUSES(state, payload) {
			state.STATUSES = payload;
		},
		SET_NOTIFICATIONS(state, payload) {
			state.NOTIFICATIONS = payload;
		},
		PUSH_NOTIFICATION(state, payload) {
			state.NOTIFICATIONS.unshift(payload);
		},
		READ_NOTIFICATION(state, payload) {
			const item = state.NOTIFICATIONS.find(item => item.id == payload);
			if (item.unread) {
				state.NOTIFICATIONS[state.NOTIFICATIONS.indexOf(item)].unread = false;
			}
		}
	},
	actions: {
		async INIT(context) {
			//await context.dispatch('FETCH_STATUSES')
			// await context.dispatch('AUTHENTICATE_USER')
			await context.dispatch('FETCH_NOTIFICATIONS')
		},
		SHOPPING_BASKET(context, payload) {
			context.commit('SHOPPING_BASKET', payload);
		},
		async FETCH_STATUSES(context) {
			api.status.get().then(res => {
				context.commit('SET_STATUSES', res);
			})
		},
		async AUTHENTICATE_USER({ commit }) {
			return api.authenticate().then((res) => {
				console.log(res)
				commit('SET_USER', res);
			})
				.catch((err) => {
					throw err
				})
		},
		async FETCH_NOTIFICATIONS(context) {
			context.commit('SET_NOTIFICATIONS', [])
		},
		DATABASE_DATA_VALUES(ctx) {
			let values = [];

			DIFFANALYS.fields.forEach(field => {
				if (field.Value != null) {
					values.push(field);
				}
			});
			ctx.commit('SET_DATABASE_VALUES', values);
		}
	},
	getters: {
		GET_STATUSES(state) {
			return state.STATUSES;
		},
		GET_DIFFANALYS(state) {
			return state.DIFFANALYS;
		},
		GET_DATABASE_VALUES(state) {
			return state.DATABASE_VALUES;
		},
		GET_CURRENT_PRODPLAN(state) {
			return state.CURRENT_PRODPLAN;
		},
		GET_DATA(state) {
			return state.DATA;
		},
		GET_REQUEST(state) {
			return state.REQUEST;
		},
		GET_EXTRA_HEADERS(state) {
			return state.EXTRA_HEADERS;
		},
		GET_USER(state) {
			return state.USER;
		}
	}
})
