import {
	renderBooleanFromNumber
} from "./renderUtils";

import moment from "moment";

const LockerActive = (current) => `<i class="fas ${renderBooleanFromNumber(current.LockerActive, 'check green', 'times red')}"></i>`;
const ImagePath = (current) => `<i class="fas fa-${current.ImagePath ? 'image' : ''}"></i>`;
const GroupAmount = (current) => `<i title="${current.GroupAmount > 0 ? 'Artikeln finns i grupp' : ''}" class="fas fa-${current.GroupAmount > 0 ? 'object-group' : ''}"></i>`;

const LastCommunication = (current) => {
	return moment.utc(current?.LastCommunication).local().format('DD-MM-YYYY HH:mm');
};

const CurrentStatus = (current) => `
	
	${current.CurrentStatus =='Idle' ? '<i class="fas fa-stopwatch" style="color:#BCEA12;"></i> Yes&nbsp;&nbsp;' : ''}
	${current.CurrentStatus =='Idle' ? '<i class="fas fa-stopwatch " style="color:#BCEA12;"></i> Yes &nbsp;&nbsp;' : ''}
	${current.CurrentStatus =='Idle' ? '<i class="fas fa-stopwatch " style="color:#BCEA12;"></i> Yes &nbsp;&nbsp;' : ''}
`



export const useRenderFunctions = () => ({
	LockerActive,
	ImagePath,
	GroupAmount,
	CurrentStatus,
	LastCommunication
});
