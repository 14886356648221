export { createNav } from './config'
import { createRouter, createWebHistory } from "vue-router";
import * as collections from './modules/index'
import { standard, modules, createRoutes } from './config';
import Home from "@/views/Home";
import Login from "@/root/Login";


import * as crud from "./modules/crud"

const {admin } = collections;

export const routesList  = [
	{
		name: "Home",
    path: "/",
		component: Home,
    nav: {
			name: "Start",
			icon: "home",
		}
  },
  {
		name: "Login",
    path: "/login",
		component: Login,
    meta: {
			panelMode: true
    }
	},
	admin
];


const { user, worker, supportGuide } = crud;
const crudRoutes = [...user, ...worker, ...supportGuide] ;

export const routes = [...routesList, ...crudRoutes];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: createRoutes(routes, [...standard, ...modules])
});

export default router;
