import { createApp } from "vue";
import { registerComponents } from '@/services/core';
import '@/scss/main.scss';
import '@fortawesome/fontawesome-free/css/all.min.css'
import App from "@/root/App.vue";
import { RecycleScroller } from '@/plugins/virtual-scroller'

//Create app
const app = createApp(App);

//Plugins
import router from "./router";
import store from "./store";

//Directives
import { sortable } from '@/directives/sortable';
import { draggable } from '@/directives/draggable';
import { dropzone } from '@/directives/dropzone';

app.directive('sortable', sortable)
app.directive('draggable', draggable)
app.directive('dropzone', dropzone)

/* Components */
app.component('RecycleScroller', RecycleScroller)

/* Global components */
const globals = require.context('@/components/globals', false, /glob-[\w-]+\.vue$/);
const charts = require.context('@/components/charts', false, /chart-[\w-]+\.vue$/);
const widgets = require.context('@/components/widgets', false, /widget-[\w-]+\.vue$/);
const inputs = require.context('@/components/inputs', false, /input-[\w-]+\.vue$/);
registerComponents(app, [
	globals,
	charts,
	widgets,
	inputs
]);

router.beforeEach(async (to, from, next) => {

	let authStatus = store.state.AUTHENTICATED;
  	switch (authStatus) {
		case true:
			if (to.path.includes('/login')) {
				next(`/login/?redirect=${to.path}`);
			}
			else{
				next();
			}
			break;
		default:
			try {
				await store.dispatch('AUTHENTICATE_USER');
				next({path:to.fullPath, replace:true});
			} catch (error) {
				if (to.path.includes('/login')) {
					next();
				}else{
					next({ path: '/login', replace:true });
				}
			}
			break
  	}
	// if (!store.state.AUTHENTICATED) {
	// 	await store.dispatch('AUTHENTICATE_USER');
	// }
	// if (!to.path.includes('/login')) {
	// 	if (to.meta.noAuth) {
	// 		next();
	// 		return;
	// 	}
	// 	if (store.state.AUTHENTICATED) {
	// 		next();
	// 	} else {
	// 		next(`/login/?redirect=${to.path}`);
	// 	}
	// } else {
	// 	next();
	// }
});


import { i18n } from '@/plugins/i18n/lang.js'
app.use(i18n)

app.use(store);
app.use(router);
app.mount("#app");

