<template>
    <div class="glob-slider">
      <label v-if="label">{{ label }}</label>
      <vue-slider
        v-model="localValue"
        @change="(val) => emitValue(val)"
        @drag-start="isDragActive = true"
        @drag-end="isDragActive = false"
        v-bind="options"
      />
    </div>
  </template>
  
  <script>
  import { ref, computed, watch } from 'vue';
  import VueSlider from 'vue-slider-component';
  import 'vue-slider-component/theme/antd.css';
  
  export default {
    props: {
      min: {
        type: Number,
        required: false,
        default: 0
      },
      max: {
        type: Number,
        required: false,
        default: 30
      },
      dotSize: {
        type: Number,
        required: false,
        default: 20
      },
      interval: {
        type: Number,
        required: false,
        default: 1
      },
      height: {
        type: Number,
        required: false,
        default: 10
      },
      tooltip: {
        type: String,
        required: false,
        default: 'active'
      },
      tooltipPlacement: {
        type: String,
        required: false,
        default: 'top'
      },
      label: {
        type: String,
        required: false
      },
      width: {
        type: [String, Number],
        required: false,
        default: 'auto'
      },
      modelValue: {
        type: [String, Number],
        required: false,
        default: 0
      },
      updateAfterChange: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {
      VueSlider
    },
    setup(props, { emit }) {
      const localValue = ref(props.modelValue);
      const isDragActive = ref(false);
  
      const options = computed(() => ({
        dotSize: props.dotSize,
        height: props.height,
        width: props.width,
        min: props.min,
        max: props.max,
        interval: props.interval,
        tooltip: props.tooltip,
        tooltipPlacement: props.tooltipPlacement,
        useKeyboard: false,
      }));
  
      const emitValue = (value) => {
        if (!props.updateAfterChange) {
          emit('update:modelValue', value);
        } else if (!isDragActive.value) {
          emit('update:modelValue', value);
        }
      };
  
      watch(isDragActive, (newVal) => {
        if (props.updateAfterChange && !newVal) {
          emit('update:modelValue', localValue.value);
        }
      });
  
      watch(() => props.modelValue, (newVal) => {
        localValue.value = newVal;
      });
  
      return {
        localValue,
        isDragActive,
        options,
        emitValue
      };
    }
  };
  </script>
  
  <style lang="scss">
  .glob-slider {
    label {
      font-size: 15px;
    }

    .vue-slider-process {
    background: linear-gradient(180deg, rgb(10, 190, 188), rgb(39, 159, 189)) !important;
    }

    .vue-slider-dot-handle{
        border: 2px solid rgb(39, 159, 189);
    }
  }
  </style>
  