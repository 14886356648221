<template>
	<div class="ips-notifications-container">
		<button @click="toggleNotifications" class="ips-notifications-toggle" :class="hasUnread ? 'ips-notifications-toggle--unread' : ''">
			<i class="fas fa-bell"></i>
		</button>
		<transition name="fade">
			<Notifications :show="show" @mouseleave="closeNotifications"/>
		</transition>
	</div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import Notifications from './Notifications'
export default {
	components: {
		Notifications
	},
	setup() {
		const show = ref(false);
		const store = useStore();
		const notifications = computed( () => store.state.NOTIFICATIONS )

		const hasUnread = computed(() => notifications.value.some(i => i.unread))

		const toggleNotifications = () => {
			show.value = !show.value;
		}

		const closeNotifications = () => {
			show.value = false;
		}

		return {
			hasUnread,
			show,
			toggleNotifications,
			closeNotifications
		}
	}
}
</script>

<style lang="scss">
	.ips-notifications-container {
		position: relative;
	}
	.ips-notifications-toggle {
		margin: 0.5rem;
		position: relative;
		i {
			color: $rpa-bitterLemonGreen;
			font-size: 1.2rem;
		}
		&--unread {
			&:after {
				content: '';
				display: block;
				width: .5rem;
				height: .5rem;
				border-radius: 100px;
				position: absolute;
				top: -.25rem;
				right: -.25rem;
				background-color: $kits-red;
			}
		}
	}
</style>