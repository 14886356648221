export default {
	name: 'Arbetare',
	slug: 'workers',
	methods: ['DELETE', 'EDIT', 'CREATE'],
	icon: 'microchip',
	description: '',
	template: {
		name: 'Name',
		component: 'EditUser'
	},
	createTemplate: {
		component: 'CreateUser'
	},
	quickEdit: [
		'Name',
		'LastName',
		'Email',
		'EmployeeNumber',
		'Password',
	],
	fieldGroups: [
		{
			key: 'info',
			name: 'Allmänt',
			fields: [
				{
					key: 'Name',
					type: 'text'
				},
				{
					key: 'LastName',
					type: 'text'
				},
				{
					key: 'Email',
					type: 'email'
				},
				{
					key: 'Password',
					type: 'password'
				},
				{
					key: 'EmployeeNumber',
					type: 'number'
				}
			]
		}
	],
	head: [
		{ Alias: "Id", Value: "ID" },
		{ Alias: "Name", Value: "Namn" },
		{ Alias: "LastName", Value: "Efternamn" },
		{ Alias: "LastLogin", Value: "Senast inloggad" },
		{ Alias: "EmployeeNumber", Value: "Anställningsnummer" },
		{ Alias: "Pin", Value: "Pin" },
		{ Alias: "Password", Value: "Lösenord" },
		{ Alias: "Email", Value: "Epost" },
		{ Alias: "AccountPermission", Value: "Behörighet" },
		{ Alias: "CustomerName", Value: "Företag" },
	]
}