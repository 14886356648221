<template>
  <tbody v-if="enableEditing" class="ips-table-body">
    <tr v-for="item in localData" :key="generateId(item.Id)" class="ips-table-body__row">
      <!-- Item -->
      <td v-for="entry in data.head" :key="generateId(entry.Alias)">
        <input
          style="padding: .25rem .5rem;"
          @input="$emit('change-item', item)"
          v-if="getEditableField(entry)"
          v-model="item[entry.Alias]"
          :type="getEditableField(entry).type"
        />
        <span v-else v-html="renderer.render(item, entry.Alias)"></span>
      </td>
      <!-- Actions -->
      <td v-if="actions" style="padding:0px!important;">
        <span v-for="action in actions" :key="generateId(action.name)">
          <span v-if="action.if ? (typeof action.if == 'function' ? action.if(item) : action.if) : true">
            <button
              class="button button button--action"
              @click="execAction(action, item, $event)"
              :title="action.description && action.description"
              :class="generateButtonClasslist(action, item)"
            >
              <i :class="'fa' + action?.icon"></i>
              {{ action.name }}
            </button>
          </span>
        </span>
      </td>
    </tr>
  </tbody>
  <tbody v-else class="ips-table-body">
    <tr v-for="item in localData" :key="generateId(item.Id)" class="ips-table-body__row">
      <!-- Item -->
      <td v-for="entry in data.head" :key="generateId(entry.Alias)" v-html="renderer.render(item, entry.Alias)"></td>
      <!-- Actions -->
      <td v-if="actions" style="padding:0px!important;">
        <span v-for="action in filteredActions" :key="generateId(action.name)">
          <template v-if="action.if ? action.if(item) : true">
          <button
            class="button button button--action"
            @click="execAction(action, item, $event)"
            :title="action.description && action.description"
            :class="generateButtonClasslist(action, item)"
          >
            <i v-if="action.icon" :class="'fa ' + action?.icon" style="margin-right:3px;"></i>
            {{ action.name }}
          </button>
          </template>
        </span>
      </td>
    </tr>
  </tbody>
  <GlobModal
    :width="10"
    :isLoaded="activeAction && activeAction.isLoaded"
    :show="activeAction && activeAction.isLoaded"
    @close="activeAction = null"
    title="Är du säker?"
  >
    <p
      style="margin: .5rem 0 0 0; text-align: center;"
      v-html="activeAction.action.requirements.consequence(activeAction.item)"
    ></p>
    <div style="display: flex; margin: 0 1rem;">
      <button
        class="button button--red button--margin"
        @click="doFinalAction()"
        :title="activeAction.action.description"
      >
        Ja
      </button>
      <button class="button button--orange button--margin" style="margin-left: auto;" @click="activeAction = null">
        Nej
      </button>
    </div>
  </GlobModal>
</template>

<script>
import { useRenderFunctions, useItemRenderer } from "@/components/globals/table/common";
import { generateId } from "@/services/utils";
import { reactive, ref, onMounted, computed, watchEffect } from "vue";
export default {
  emits: ["change-item"],
  props: ["data", "itemRenderFunctions", "enableRenderFunctions", "actions", "enableEditing", "fields", "sortingData"],
  setup(props) {
		const { sortingData } = reactive(props);
    const generateButtonClasslist = (action) => {
      const style = `button--${action.buttonStyle}`;
      const color = `button--${action.color}`;
      return style + " " + color;
    };

    const renderFunctions = props.itemRenderFunctions || (props.enableRenderFunctions && useRenderFunctions());
    const renderer = useItemRenderer(renderFunctions);

    //Actions
    let visibleReqs = reactive([]);
    let activeAction = ref(null);
    const execAction = (action, item, e) => {
      e.stopPropagation();
      if (action.requirements) {
        activeAction.value = {
          isLoaded: true,
          action,
          item,
        };
      } else {
        action.action(item);
      }
    };
    const doFinalAction = () => {
      activeAction.value.action.action(activeAction.value.item);
      activeAction.value = null;
    };

    const getEditableField = (item) => {
      const field = props.fields.find((field) => field.key == item.Alias);
      if (field) {
        return field;
      } else {
        return false;
      }
    };

    const localData = computed(() => {
      if (props.sortingData.key) {
        return props.data.body
          .map((i) => Object.assign({}, i))
          .sort((a, b) => {
            if (a[props.sortingData.key] < b[props.sortingData.key]) {
              return props.sortingData.descending ? -1 : 1;
            }
            if (a[props.sortingData.key] > b[props.sortingData.key]) {
              return props.sortingData.descending ? 1 : -1;
            }
            return 0;
          });
      } else {
        return props.data.body.map((i) => Object.assign({}, i));
      }
    });

    const log = (val) => {
      console.log(val);
    };

    const filteredActions = computed(() => props.actions);

    return {
      renderer,
      generateButtonClasslist,
      generateId,
      execAction,
      activeAction,
      doFinalAction,
      getEditableField,
      localData,
      log,
      filteredActions,
    };
  },
};
</script>

<style lang="scss" scoped>
.ips-table-body {
  &__row {
    color: #fff;
    height: 60px;

    &:nth-child(even) {
      background-color: rgba(255, 255, 255, 0.1);
    }
   
    &:hover {
      background-color: rgba(0,52,93,0.3);
    }
    td {
      padding: $cellpadding;
      font-weight: 500;
      font-size: 15px;
      color: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
