
import SupportGuide from "@/views/crud/SupportGuide/Index.vue";

export const supportGuide = [{
  path: "/admin/view/support-guides",
  component: SupportGuide,
  isCRUD: true,
  name: "SupportGuide",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Support and guides" }]
  },
}
]