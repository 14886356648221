<template>
  <div style="max-width: 99%">
    <GlobXpanel class="admin-view" title="Administrera" v-if="$route.name == 'Admin'">
      <div class="kits-admin-cruds">
        <router-link
          :to="`/admin/view/${crud.slug}`"
          class="kits-admin-cruds__item"
          v-for="crud in filteredCruds"
          :key="'crud-' + crud.key"
        >
          <div class="kits-admin-cruds__item-header">
            <div class="kits-admin-cruds__item-icon">
              <i :class="`fas fa-${crud.config.icon}`"></i>
            </div>
          </div>
          <div class="kits-admin-cruds__item-content">
            <h3>{{ crud.name }}</h3>
          </div>
        </router-link>
      </div>
    </GlobXpanel>
    <MainView/>
  </div>
</template>

<script>
import { contentTypes, routes, cruds } from "./config";
import MainView from "./MainView.vue";
import userGroups from './config/userGroups'
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  components: {
		MainView
	},
	setup() {
		const store = useStore();
		const user = computed(() => store.state.USER);
		const filteredCruds = computed(() => cruds.filter(i => {

      if(user.value.UserGroup == 'SuperAdmin'){
        return true;
      }

      if(i.slug == 'devices') {
        return false;
      }
      
			if(i.isCustom){
				return true;
			}
			if (userGroups[user.value.UserGroup]) {
				if (userGroups[user.value.UserGroup] === '*') {
					return true
				} else {
					return userGroups[user.value.UserGroup].includes(i.slug);
				}
			} else {
				return false
			}
		}))
		return {
      types: contentTypes,
      routes,
			cruds,
			filteredCruds
		}
	},
};
</script>

<style lang="scss">
.kits-admin-cruds {
	display: flex;
	flex-flow: row wrap;
  &__item {
		width: 200px;
		margin-right: 1rem;
		margin-top: 1rem;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    &-header {
      position: relative;
      background-color: rgba(51, 178, 208, 0.3)!important;
      width: 100%;
      height: 2.5rem;
      border-top-right-radius: 3px;
      border-top-left-radius: 3px;
    }
    &-content {
      padding-top: 1.5rem;
      background: #30aecc;
      color: white;
    }
    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 4rem;
      height: 4rem;
      border-radius: 100px;
      background-color: white;
      border: 3px solid $kits-gray;
			position: absolute;
			font-size: 1.2rem;
      top: 5px;
      left: 50%;
      transform: translateX(-50%);
      color: $kits-steelGray;
      &.active {
        border: 3px solid $kits-green;
      }
    }
  }
}
.ips-xpanel {
  background: #1b556bd4 !important;
}

.admin-view {
  .ips-xpanel__title {
    margin-left: -1rem !important;
    background: red;
    margin-right: -1rem;
    margin-top: -1rem;
    background: linear-gradient(180deg, rgba(10, 190, 188, 0.6),rgba(39, 159, 189, 0.6)) !important;
  } 

  .ips-xpanel__title-left {
    padding-top: 1rem;
    padding-left: 1rem;
  }

  .kits-admin-cruds__item-header {
    background: linear-gradient(180deg, #0ABEBC 0%, #279FBD 100%)!important;
  }

}
</style>
