
import Worker from "@/views/crud/Worker/Workers.vue";

export const worker = [{
  path: "/admin/view/workers",
  component: Worker,
  isCRUD: true,
  name: "Worker",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Workers" }]
  },
}
]