<template>
  <div class="ips-navbar" @mouseleave="closeNav" @click="openNav" :class="activeNav ? 'active' : ''">
    <div class="ips-navbar-items">
      <div class="ips-navbar-items__item ips-navbar-items__item--logo">
        <div class="ips-navbar-items__item-parent">
          <div class="ips-navbar-items__item-icon">
            <img src="@/assets/logo.png" class="logo" @click="$router.push('/')"/>
          </div>
          <div class="ips-navbar-items__item-content">
            <span>RPA Dashboard</span>
          </div>
        </div>
      </div>
      <div
        class="ips-navbar-items__item"
        :class="
          `${isExpanded(item) ? 'ips-navbar-items__item--active' : ''} ${
            isCurrent(item) || hasActiveChild(item) ? 'ips-navbar-items__item--current' : ''
          }`
        "
        @click="handleClick(item, $event)"
        v-for="item in filteredMenu"
        :key="`menu-item-${item.id}`"
      >
        <div class="ips-navbar-items__item-parent">
          <div class="ips-navbar-items__item-icon">
            <i :class="`fas fa-${item.nav.icon}`"></i>
            <span>{{ item.nav.name }}</span>
          </div>
          <div class="ips-navbar-items__item-content">
            {{ item.nav.name }}
          </div>
        </div>
        <div
          class="ips-navbar-items__item-children"
          :class="`${isExpanded(item) ? 'ips-navbar-items__item-children--active' : ''}`"
          :style="getChildrenStyles(item)"
        >
          <div
            class="ips-navbar-items__item-child"
            :class="`${isCurrent(child) ? 'ips-navbar-items__item-child--current' : ''}`"
            v-for="child in item.children"
            :key="`menu-child-${child.id}`"
          >
            <router-link :to="{ path: child.path }">{{ child.nav.name }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuItems from "./menuItems";
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { routes, createNav } from "@/router";
export default {
  setup() {
    const router = useRouter();
    const expanded = ref([]);
    const handleClick = (item, e) => {
      if (item.children && item.children.length > 0) {
        if (isExpanded(item)) {
          expanded.value.splice(expanded.value.indexOf(item.id), 1);
        } else {
          expanded.value = [];
          expanded.value.push(item.id);
        }
      } else {
				e.stopPropagation();
        router.push(item.path);
      }
    };
    const isExpanded = (item) => {
      return expanded.value.includes(item.id);
    };
    const getChildrenStyles = (item) => {
      return isExpanded(item) ? `max-height: ${item.children.length * 40}px` : "";
		};
		
		const activeNav = ref(false);
    const closeNav = () => {
      expanded.value = [];
			activeNav.value = false;
    };
    const openNav = () => {
      activeNav.value = true;
    };

    const path = computed(() => router.currentRoute.value.path);
    const isCurrent = (item) => {
      return item.path && item.path == path.value;
    };
    const hasActiveChild = (item) => {
      return router.currentRoute.value.matched.map((i) => i.path).includes(item.path);
    };


    const filteredMenu = createNav(routes);

    return {
      filteredMenu,
      handleClick,
      isExpanded,
      getChildrenStyles,
      expanded,
      closeNav,
      isCurrent,
      hasActiveChild,
			openNav,
			activeNav
    };
  }
};
</script>

<style lang="scss" scoped>
.ips-info {
  &__content {
    margin: 1rem 0;
    p {
      margin: 0.5rem 0;
    }
  }
}
.ips-navbar {
  z-index: 10;
  height: 100vh;
  width: 100px;
  overflow: hidden;
  overflow-y: auto;
  position: sticky;
  left: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(180deg, $rpa-black 0%, $rpa-smokyBlack 100%);
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  @include scrollbar(4px);
  &.active {
    width: 220px;
  }
  &.active &-items__item {
    &-icon {
      span {
        opacity: 0;
      }
      i {
        transform: translateY(0.6rem);
      }
    }
  }
  &.active &-items__item-content {
    opacity: 1;
  }
}
.ips-navbar-items {
  &__item {
    margin-bottom: 2rem;
    &-parent {
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      display: flex;
      align-items: center;
    }
    &-content {
      transition: opacity 0.2s ease-in;
      color: white;
      font-size: 0.7rem;
      max-width: 0;
      opacity: 0;
      text-transform: uppercase;
    }
    &-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100px;
      padding: 2rem 0!important;
      i {
        transition: all 0.3s ease-in-out;
        font-size: 1.5rem;
        color: white;
      }
      span {
        transition: all 0.3s ease-in-out;
        font-size: 12px;
        display: block;
        text-transform: uppercase;
        color: white;
        margin-top: 5px;
      }
      img {
        height: 25px;
        width: auto;
        transition: all 0.3s ease-in-out;
        // filter: grayscale(100%) brightness(2);
        &:hover {
          filter: grayscale(0%) brightness(2);
        }
      }
    }
    &-children {
      max-height: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
    }
    &-child {
      transition: all 0.2s ease-in-out;
      position: relative;
      a {
        height: 40px;
        display: flex;
        align-items: center;
        padding-left: 1rem;
        color: white;
        font-weight: 400;
        font-size: 0.8rem;
        text-decoration: none;
      }
      &:hover {
        background-color: darken($kits-green, 10%);
      }
    }
    &--current &-parent {
      i{
          color: $rpa-bitterLemonGreen;
      }
    }
    &--active &-parent {
      @include box-shadow(4);
    }
    &--active &-children {
      background-color: darken($kits-green, 3%);
    }
    &--logo {
      height: 50px;
      img {
        height: 50px;
        width: 50px;
        transition: all 0.3s ease-in-out;
        // filter: grayscale(100%) brightness(2);
      }
      &:hover img {
        filter: grayscale(0%) brightness(2);
      }
    }
    &--logo &-icon {
      padding: 0.5rem 0;
    }
  }
}


</style>
