<template>
  <div>
    <label
      class="ips-small-toggle__label"
      @click="updateValue()"
      :class="flex ? 'flex' : ''"
      :style="margin ? 'margin: .5rem;' : ''"
      v-if="label"
    >
      <span>{{ label }}</span>
      <div class="ips-small-toggle" :class="`${state ? 'active' : ''} ${center ? 'center' : ''}`">
        <div class="ips-small-toggle__track">
          <div class="ips-small-toggle__knob"></div>
        </div>
      </div>
    </label>
    <div v-else class="ips-small-toggle" :class="`${state ? 'active' : ''} ${center ? 'center' : ''}`">
      <div class="ips-small-toggle__track" @click="updateValue()">
        <div class="ips-small-toggle__knob"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { props } from "./common";
import { ref } from 'vue';
export default {
	props: [...props, "label", "flex", "margin", "center"],
	setup(props, { emit }) {
		const getBoolean = (val) =>  {
			if (typeof val == 'number') {
				return val > 0 ? true : false
			} else {
				return val
			}
		}
		let state = ref(getBoolean(props.modelValue));
		const updateValue = (color) => {
      state.value = !getBoolean(state.value);
			emit('update:modelValue', state.value)
		}

		
		return {
			updateValue,
			state
		}
	}
};
</script>

<style lang="scss">
.ips-small-toggle {
  width: 3rem;
  margin: 0.4rem 0;
  height: 0.8rem;
  cursor: pointer;
  &.center {
    margin: 0 auto;
  }
  &__label {
    cursor: pointer;
    &.flex {
      display: inline-flex;
      align-items: center;
      span {
        margin-right: 0.5rem;
        text-transform: uppercase;
        font-size: 0.7rem;
      }
    }
  }
  &__track {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background: linear-gradient(90deg, #3DC95C 0%, #068903 85%);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  &__knob {
    transition: all 0.3s ease-in-out;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 100px;
    background-color: white !important;
    @include box-shadow(3);
    position: absolute;
    left: -1px;
    top: calc(50% - 0.75rem);
  }
  &.active &__knob {
    left: calc(100% - (1.5rem - 1px));
    background-color: $kits-green;
  }
}
</style>
