<template>
	<div id="ips-main-view" :class="getClassNames($route.meta.panelMode, $route.meta.noPadding)" role="main" >
	<StatusField v-if="!$route.meta.panelMode && auth"/>
		<div id="app" class="ips-main-view">
			<router-view v-slot="{ Component }">
				<component :is="Component"/>
			</router-view>
		</div>
	</div>
</template>

<script>
import StatusField from './StatusField.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
export default {
	name: 'MainView',
	components: {
		StatusField
	},
	setup(props) {
		const store = useStore();
		const getClassNames = (panelmode, nopadding) => {
			let classes = '';
			classes += panelmode || nopadding ? 'ips-main-view--panelmode ' : '';
			return classes;
		}
		const auth = computed(() => store.state.AUTHENTICATED);
		return {
			getClassNames,
			auth
		}
	}
}
</script>

<style lang="scss" scoped>
	.ips-main-view {
		padding: .5rem;
		margin-top: 1rem !important;
		flex: 1;
		&--panelmode {
			padding-left: 0;
		}
	}
	#ips-main-view {
		flex: 1;
	}
</style>