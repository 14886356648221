<template>
	<div class="ips-status-field">
		<div class="ips-status-field__left">
			<div class="ips-user-welcome">
				<span class="ips-user-welcome-header">Welcome, </span>
				<span class="ips-user-welcome-value">{{ user.Name }}!</span>
			</div>
			<Breadcrumbs/>
		</div>
		<div class="ips-status-field__right">
			<Search />
			<NotificationsToggle/>
			<User/>
		</div>
	</div>
	<div id="loader-portal"></div>
	<div id="status-field-portal"></div>
</template>

<script>
import User from './status/User';
import Breadcrumbs from './status/Breadcrumbs';
import NotificationsToggle from './status/NotificationCenter/Toggle';
import Search from './status/Search';
import { ref, computed } from 'vue';
import { useStore } from 'vuex'
export default {
	setup() {
		const store = useStore();
		const user = computed(() => store.state.USER);

		return {
			user,
		};
	},
	components: {
		User,
		NotificationsToggle,
		Breadcrumbs,
		Search,
	},
}
</script>

<style lang="scss" scoped>
	.ips-status-field {
		top: 0;
		height: 70px;
		background: rgba(51, 178, 208, 0.3);
		// opacity: 0.3;
		padding-left: .5rem;
		display: flex;
		align-items: center;
		font-size: .8rem;
		&__right {
			display: flex;
			align-items: center;
			margin-left: auto;
		}
	}

	.ips-user-welcome-header {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 300;
		font-size: 24px;
		line-height: 30px;
		color: #3ED9FE;
	}
	.ips-user-welcome-value {
		font-family: 'Inter';
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 30px;
		color: rgba(255, 255, 255, 1)
	}
	.ips-user-welcome{
		margin-left: 15px;
	}
</style>