/* Standard routes */
export const standard = [
	'Login',
	'Home',
]

/* Specialized modules */
export const modules = [
	'Materialplanning',
	'Storage',
	'Admin'
]