<template>
	<GlobRow>
			<GlobCol :medium="12">
				<GlobSpinner :isLoading="isLoading"/>
				<GlobXpanel :noShadow="true" :tools="false" :title="$t('general.general')">
					<GlobRow>
						<GlobCol :medium="3" v-if="userStore.UserGroup == 'SuperAdmin'">
							<label>
								{{$t('administration.user.fields.customer')}}*
								<InputDynamic
								:fullWidth="true"
								:type="'virtualselect'"
								:placeholder="$t('administration.user.fields.customer')"
								v-model="user.form.CustomerId"
								:data="customers"
								@input="customerChanged()"
								/>
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.name')}}*
								<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.user.fields.name')"
								v-model="user.form.Name"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.last_name')}}*
								<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.user.fields.last_name')"
								v-model="user.form.LastName"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.email')}}*
								<InputDynamic
								:fullWidth="true"
								:type="'email'"
								:placeholder="$t('administration.user.fields.email')"
								v-model="user.form.Email"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.cell_phone')}}
								<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.user.fields.cell_phone')"
								v-model="user.form.CellPhone"
								/>
							</label>
						</GlobCol>
					</GlobRow>
				</GlobXpanel>
				<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.user.security')">
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.employee_number')}}
								<InputDynamic
								:fullWidth="true"
								:type="'text'"
								:placeholder="$t('administration.user.fields.employee_number')"
								v-model="user.form.EmployeeNumber"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.pin')}}
								<InputDynamic
								:fullWidth="true"
								:type="'number'"
								:placeholder="$t('administration.user.fields.pin')"
								v-model="user.form.Pin"
								/>
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.password')}}
								<InputDynamic
								:fullWidth="true"
								:type="'password'"
								:placeholder="$t('administration.user.fields.password')"
								v-model="user.form.Password"
								/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.confirm_password')}}
								<InputDynamic
								:fullWidth="true"
								:type="'password'"
								:placeholder="$t('administration.user.fields.confirm_password')"
								v-model="user.form.ConfirmPassword"
								/>
							</label>
						</GlobCol>
					</GlobRow>
				</GlobXpanel>
				<GlobXpanel :noShadow="true" :tools="false" :title="$t('administration.user.additional_settings')">
					<GlobRow>
						<GlobCol :medium="9">
							<label>
								{{$t('administration.user.fields.roles_and_resposibility')}}
								<InputDynamic :fullWidth="true" :type="'multiselect'"
									:placeholder="$t('administration.user.fields.roles_and_resposibility')"
									v-model="user.form.AccountRoles" :data="filteredRoles"/>
							</label>
						</GlobCol>
						<GlobCol :medium="3">
							<label>
								{{$t('administration.user.fields.account_active')}}
								<InputToggle 
										:flex="true" 
										:showLabelRightSide="true"
										v-model="user.form.AccountActive" />
							</label>
						</GlobCol>
					</GlobRow>
					<GlobRow>
						<GlobCol :medium="12">
							<p class="text-danger mas-mt-2" v-if="errorMessage">{{errorMessage}}</p>
							<button @click="save" class="button mas-mt-4" :disabled="isSaveButtonDisabled">{{$t('general.button.save')}}</button>
						</GlobCol>
					</GlobRow>
				</GlobXpanel>
			
			</GlobCol>
		</GlobRow>

</template>

<script>
import { reactive, ref, computed, watchEffect } from "vue";
import { useI18n } from 'vue-i18n';
import { useApi } from "@/plugins/api";
import {useRouter } from "vue-router";
import { useNotifications } from "@/plugins/notifications";
import { validateForm } from "@/views/crud/User/common.js";
import { useStore } from 'vuex'

export default {
  props: ['roles', 'customers'],
  setup(props, {emit}) {

	const store = useStore();
	const userStore = computed(() => store.state.USER);
    const { tm } = useI18n();
	const endpoint = computed(() => api.users);
	const api = useApi();
	const router = useRouter();

	const notifications = useNotifications();
	const errorMessage = ref(null);
	const isLoading = ref(false);

	const user = reactive({
		form: {
			CustomerId: "",
			Name: "",
            LastName: "",
            Email: "",
			CellPhone: "",
            Pin: "",
            EmployeeNumber: "",
            Password: "",
            ConfirmPassword: "",
			AccountActive: true,
			AccountRoles: []
		},
	});


	const filteredRoles = computed(() => {
		if(userStore.value.UserGroup == 'SuperAdmin'){
			if(user.form.CustomerId){
				return props.roles.filter( i => i.CustomerId == user.form.CustomerId);
			}else{
				return [];
			}
		}else{
			return props.roles;
		}
	})

	const customerChanged = () => {
		user.form.AccountRoles = [];
	}

    const save = () => {
		
		errorMessage.value = validateForm(user.form);

		if(errorMessage.value){
			return false;
		}

		isLoading.value = true;
		endpoint.value.create(user.form).then((res) => {
			isLoading.value = false;
			if(res.success){
				notifications.push(
					{
						msg: tm('administration')['user']['create_success']
					},
					"success",
					false
				);
				emit('created')
				emit('close')
			}else{
				for(let errorField in res.errors){
					console.log(res.errors[errorField][0]);
				 	let error = JSON.parse(res.errors[errorField][0]);
					errorMessage.value =  tm(error.error).replace('{attribute}', tm(error.attribute));
					break;
				}
			}

			
		});
    };

	const isSaveButtonDisabled = computed(() => {
		return (
			!user.form.Name ||
			!user.form.LastName ||
			!user.form.Email ||
			(userStore.value.UserGroup == 'SuperAdmin' && !user.form.CustomerId)
		) ? true : false;
	})


    return {
      save,
	  errorMessage,
	  user,
	  isLoading,
	  isSaveButtonDisabled,
	  userStore,
	  filteredRoles,
	  customerChanged
    };
  },
};
</script>

<style lang="scss" scoped>
// .ips-modal__content {
// 	overflow: scroll;
// 	height: 80vh;
// }

.ips-xpanel {
	background: none !important;
}
</style>