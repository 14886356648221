
import User from "@/views/crud/User/Users.vue";

export const user = [{
  path: "/admin/view/users",
  component: User,
  isCRUD: true,
  name: "User",
  nav: {
    name: "Admin",
    icon: "fa-hdd"
  },
  meta: {
    noPadding: true,
    access: ['superadmin', 'admin'],
    breadcrumbs: [{ name: "Admin", link: "/admin" }, { name: "Users" }]
  },
}
]