<template>
	<GlobModal :show="showModal" :width="30" :height="height" :title="props.title" @close='$emit("close")'>
		<p class="ips-globmodal-confirm-message">{{props.message}}</p>
		<div class="ips-globmodal-confirm-btns mt-4">
			<button class="button button--red" @click='$emit("confirm")'
			>{{$t('general.yes')}}</button>
			<button class="button ml-2 button--orange"
			@click='$emit("close")'>{{$t('general.no')}}</button>
		</div>
	</GlobModal>
</template>

<script>

import { computed } from 'vue';
export default {
  props: ['show', 'title', 'message', 'action', 'height'],
  emits: ['close', 'confirm'],
  setup(props) {
		const showModal = computed(() => props.show);
		return {
			showModal,
			props
		}
	}
};
</script>

<style lang="scss" scoped>
.ips-globmodal-confirm-btns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.ips-globmodal-confirm-message {
    color: white !important;
}

.ips-globmodal-confirm-message {
    text-align: center;
    font-weight: 400;
    font-size: large;
}
</style>