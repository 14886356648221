<template>
  <div class="ips-table"  :style="maxHeight ? 'max-height:'+maxHeight : ''">
    <table
      >
      <TableHead :data="data" :actions="actions" @sorted="data => applySorting(data)" />
      <TableBody
        :data="data"
				:sortingData="sortingData"
        :actions="actions"
        :enableRenderFunctions="enableRenderFunctions"
        :enableEditing="enableEditing"
        :fields="fields"
        :itemRenderFunctions="itemRenderFunctions"
				@change-item="item => $emit('change-item', item)"
      />
    </table>
  </div>
</template>

<script>
import { props } from "./table/common";
import TableBody from "./table/components/Body";
import TableHead from "./table/components/Head";
import { reactive } from 'vue';
export default {
  props: props,
	emits: ['change-item'],
  components: {
    TableBody,
    TableHead
  },
  setup(props) {
		const log = val => console.log;
		let sortingData = reactive({key: false, descending: false})

		const applySorting = (data) => {
			sortingData.key = data.key;
			sortingData.descending = data.descending;
		}
		return {
			log,
			applySorting,
			sortingData
		}
	}
};
</script>

<style lang="scss" scoped>
.ips-table {
  font-size: 0.8rem;
  margin-bottom: 5rem;
  position: relative;
  max-height: 70vh;
  @include scrollbar(4px);
  overflow-y: scroll;
  @include elevation(1);
  table {
    border-collapse: collapse;
    width: 100%;
    text-align: left;
    background: rgba(40, 163, 192, 0.33);
  }
}
</style>
