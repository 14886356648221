<template>
  <GlobRow v-if="loaded">
    <GlobCol :medium="12">
      <GlobTable
          :data="{
            body: workers.body,
            head: workers.head,
          }"
          :actions="actions"
          :itemRenderFunctions="renderFunctions"
          :enableRenderFunctions="true"
        />
    </GlobCol>

    <GlobModal :show="showActionModal" :width="50" @close="closeStatusActionModal" title="RPA: Archiver">
      <div class="ips-info" style="text-align: center;">
        <GlobRow>
          <GlobCol :medium="12">
            <div class="status-header">
              <span>Requesting status from RPA ...</span>
            </div>
          </GlobCol>
        </GlobRow>

        <GlobRow>
          <GlobCol :medium="12">
            <div class="progress">
              <div class="progress-value" :style="{width: progressAnimation.width+'%'}"></div>
            </div>
          </GlobCol>
        </GlobRow>

        <GlobRow>
          <GlobCol :medium="3" class="text-start icon-color-green">Starting</GlobCol>
          <GlobCol :medium="3" class="text-start" :class="{'icon-color-green': progressAnimation.width > progressAnimation.step.first.width}">Sending Command</GlobCol>
          <GlobCol :medium="3" class="text-start" :class="{'icon-color-green': progressAnimation.width > progressAnimation.step.second.width}">Awaiting Response</GlobCol>
          <GlobCol :medium="3" class="text-start" :class="{'icon-color-green': progressAnimation.width >= progressAnimation.step.third.width}">Response Recieved</GlobCol>
        </GlobRow>

        <GlobRow class="margin-top-1">
          <GlobCol :medium="3">
            <div class="label">Status</div>
            <div class="value">
              <i class="far fa-hourglass margin-right-1 fa-lg icon-color"></i>
              <span class="label-text">{{ workerData?.CurrentStatus ?? '-' }}</span>
            </div>
          </GlobCol>
          <GlobCol :medium="5">
          <div class="label">Last run</div>
          <div class="value">
            <i class="far fa-calendar-alt margin-right-1 icon-color fa-lg"></i>
            <span class="margin-right-1 label-text" v-if="workerData?.LastCommunication">{{ moment.utc(workerData?.LastCommunication).local().format('DD-MM-YYYY') ?? '-' }}</span>
            <span class="margin-right-1 label-text" v-else>-</span>
            <i class="far fa-clock margin-right-1 icon-color fa-lg"></i>
            <span class="margin-right-1 label-text" v-if="workerData?.LastCommunication">{{ moment.utc(workerData?.LastCommunication).local().format('HH:mm') ?? '-' }}</span>
            <span class="margin-right-1 label-text" v-else>-</span>
            <i class="fas fa-check icon-color-green fa-lg"></i>
          </div>
          </GlobCol>
        </GlobRow>

        <GlobRow class="margin-top-1">
          <GlobCol :medium="12">
           <div class="label">Server Status</div>
           <div class="flex server-status">
              <div class="flex-item">
                <i class="fas fa-microchip margin-right-1 fa-lg icon-color"></i>
                <span class="margin-6-rem label-text">CPU</span>
                <span class="label-text"  v-if="workerData?.Performance">{{ workerData?.Performance?.CpuLoad ?? '-' }}%</span>
                <span class="label-text"  v-else>-</span>
              </div>
              <div class="flex-item">
                <i class="fas fa-sd-card margin-right-1 fa-lg icon-color"></i>
                <span class="label-memmory label-text">MEMMORY</span>
                <span class="label-text" v-if="workerData?.Performance">{{ workerData?.Performance?.MemoryAvailable ?? '-' }}GB/{{ workerData?.Performance?.MemoryTotal }}GB</span>
                <span class="label-text" v-else>-</span>
              </div>
              <div class="flex-item">
                <i class="far fa-hdd margin-right-1 fa-lg icon-color"></i>
                <span class="label-disk label-text">DISK</span>
                <span class="label-text">-</span>
              </div>
            </div>
          </GlobCol>
        </GlobRow>

        <GlobRow class="margin-top-3"></GlobRow>
      </div>
    </GlobModal>

    <GlobModal :show="showControllerActionModal" :width="50" @close="closeControllerActionModal" title="Remote Controller">
      <div class="ips-info" style="text-align: center;">
        <GlobRow>
          <GlobCol :medium="12">
            <div class="status-header">
              <span>PRA Achiever</span>
            </div>
          </GlobCol>
        </GlobRow>

        <GlobRow>
          <GlobCol :medium="2" class="margin-right-0">
            <button
              class="button button-normal button--action button--green"
              @click="actionStart"
            >
              <span>Start Task</span>
            </button>
          </GlobCol>
          <GlobCol :medium="5" class="margin-horizontal-1">
            <button
              class="button button-normal button--action button--blue"
              @click="showRestartConfirmModal = true"
            >
              <span>Restart Task</span>
            </button>
          </GlobCol>
        </GlobRow>

        <GlobRow class="" >
          <GlobCol :medium="5">
            <div class="flex result-wrapper">
              <label class="label">Results</label>
              <div class="margin-top-1">
                <!-- <i class="fas fa-cog icon-color-green fa-lg margin-right-1 fa-spin"></i> -->
                <span class="result-text margin-right-1">{{ currentControllerData.status || "-" }}</span>
                <i class="fas fa-check icon-color-green fa-lg"></i>
              </div>            
            </div>      
          </GlobCol>    
        </GlobRow>

        <GlobRow class="margin-top-3"></GlobRow>
        <GlobRow class="margin-top-3"></GlobRow>
      </div>
    </GlobModal>

    <GlobConsequence :show="showRestartConfirmModal" 
      @close="showRestartConfirmModal=false" 
      :height="140"
      @confirm = "actionRestart()"
      :title="$t('administration.worker.restart_worker')" 
      :message="$t('administration.worker.restart_confirm')"  
    />

    <GlobModal 
      :show="selectedWorkerToShowLog" 
      :width="50" 
      :clipOverflow="true"
      @close="selectedWorkerToShowLog=false" 
      :title="selectedWorkerToShowLog.Name.charAt(0).toUpperCase() + selectedWorkerToShowLog.Name.slice(1)+' log'"
      v-if="selectedWorkerToShowLog"
    >
      <GlobRow>
		<GlobSpinner :isLoading="isLoadingLogModal"></GlobSpinner>
        <GlobCol :medium="12">
		  <GlobSlider
		    class="margin-top-2"
			:label="'Logga att visa('+logCountToShow+')'"
			:width="'250px'"
			:min="10"
			:max="150"
			v-model="logCountToShow"
			@update:modelValue="(val) => getLogs(selectedWorkerToShowLog, val)"

		   ></GlobSlider>
          <GlobTable
		  class="margin-top-1"
            :data="{
              body: logsTable.body,
              head: logsTable.head,
            }"
			:maxHeight="'50vh'"
			:itemRenderFunctions="logRenderFunctions"
          	:enableRenderFunctions="true"
          />
        </GlobCol>
      </GlobRow>
    </GlobModal>


  </GlobRow>
  <div v-else>
		<GlobLoader/>
	</div>
</template>

<script>
import { onMounted, onUnmounted, computed, reactive, ref} from 'vue';
import { useApi } from "@/plugins/api";
import { convertDateTimeToLocal } from '@/helpers';
import moment from 'moment';
import { useStore } from 'vuex'
import GlobSpinner from '../components/globals/glob-spinner.vue';
export default {
  name: "Home",
  setup() {

	const store = useStore();
	const user = computed(() => store.state.USER);
  
    const api = useApi();
    const loaded = ref(false);
    const showActionModal = ref(false);
    const showControllerActionModal  = ref(false);
    const pollHandler = ref(0);
    const pollKiller = ref(0);
    const workerData = ref({});
    const currentControllerData = reactive({});
    const showRestartConfirmModal = ref(false);

    const selectedWorkerToShowLog = ref(null);
    const logsTable = reactive({
      body: [],
      head: [
        {Alias: 'MessageType', Value: 'Type'},
        {Alias: 'Message', Value: 'Message'},
      ]
    });
	const isLoadingLogModal = ref(false);

	const logRenderFunctions = computed(()=> {
		return {
			MessageType(obj){
				if(obj.MessageType == 'status'){
					return  'Status';
				}else if(obj.MessageType == 'nocon'){
					return 'No connection';
				}else if(obj.MessageType == 'errsev'){
					return 'Server error';
				}else if(obj.MessageType == 'errwar'){
					return 'Server warning';
				}else{
					return obj.MessageType;
				}
			},
		}
	});

	const logCountToShow = ref(50);

	let timerId;
    const progressAnimation = ref({
      step: {
        first: {
          width: 30,
        },
        second: {
          width: 60,
        },
        third: {
          width: 100,
        }
      },
      width: 0,
      timer: 0
    });
		const workers  = reactive({
			body: [],
			head: [
        		{Alias: 'ActiveAlert', Value: ''},
				{Alias: 'Name', Value: 'Namn'},
				{Alias: 'Description', Value: 'Beskrivning'},
				{Alias: 'CurrentStatus', Value: 'Status'},
				{Alias: 'LastCommunication', Value: 'Senast kontakt'},
			
			]
		})
		const getWorkerList = () => {
			api.workers.getWorkerList().then(res => {
				workers.body = res.data.map((item) => {
					item.LastCommunication = convertDateTimeToLocal(item.LastCommunication, 'YYYY-MM-DD HH:mm:ss');
					return item;
				});
				loaded.value = true;
			})
		}
    	onMounted(() => {
			if(user.value.UserGroup == 'SuperAdmin'){
				workers.head.splice(1, 0, {Alias: 'CustomerName', Value: 'Customer'})
			}
			getWorkerList();
			timerId = setInterval(getWorkerList, 60000); // 60000 milliseconds = 1 minute
		})

		onUnmounted(() => {
			clearInterval(timerId);
		})
	  const sendWorkerCommand = (Id,command) => {
		workerData.value = {};
			api.workers.sendWorkerCommand(Id,command).then(res => {

				if(res.last_repoted_worker){
					res.last_repoted_worker.Performance = JSON.parse(res.last_repoted_worker.Performance);
					workerData.value = res.last_repoted_worker;
				}

			})
		}

    function poller (id, LastCommunication) {
      animateTo(progressAnimation.value.step.second);
      pollHandler.value = setTimeout(function() {
        animateTo(progressAnimation.value.step.third);
        api
          .workers
          .getWorkerStatus(id, LastCommunication)
          .then(response => {
            if(response) {
              animateTo(progressAnimation.value.step.third, true);
              response.Performance = JSON.parse(response.Performance);
              workerData.value = response;
              getWorkerList();
            } else {
              poller(id, LastCommunication);
            }
          });

      }, 5000, id, LastCommunication);

      pollKiller.value = setTimeout(function(){
        clearTimeout(pollHandler.value);
      }, 50000, pollHandler);
    }

    function animateTo(step, end) {
      if (end) {
        clearInterval(progressAnimation.value.timer);
        progressAnimation.value.width =  100;
      }

      progressAnimation.value.timer = setInterval(() => {
        if(progressAnimation.value.width < step.width) {
          progressAnimation.value.width += 0.6;
        } else {
          clearInterval(progressAnimation.value.timer);
        }
      }, 50);

    }

    function resetStatusModel() {
      clearTimeout(pollHandler.value);
      clearTimeout(pollKiller.value);
      clearInterval(progressAnimation.value.timer);
      progressAnimation.value.width = 0;
    }

    function closeStatusActionModal() {
      showActionModal.value = false;
      resetStatusModel();
    }

    function pollControllerAction(id, LastCommunication) {
      pollHandler.value = setTimeout(function() {
        api
          .workers
          .getControllerActionStatus(id, LastCommunication)
          .then(response => {
            if(response) {
              currentControllerData.value.status = 'RPA was restarted'
              getWorkerList();
            } else {
              pollControllerAction(id, LastCommunication);
            }
          });

      }, 5000, id, LastCommunication);

      pollKiller.value = setTimeout(function(){
        clearTimeout(pollHandler.value);
      }, 50000, pollHandler);
    }

    function closeControllerActionModal() {
      showControllerActionModal.value = false;
      resetStatusModel();
    }

    function actionStart() {
      resetStatusModel();
      pollControllerAction(currentControllerData.value.Id, currentControllerData.value.LastCommunication);
      sendWorkerCommand(currentControllerData.value.Id,'runMain');
    }

    function actionRestart() {
      resetStatusModel();
      pollControllerAction(currentControllerData.value.Id, currentControllerData.value.LastCommunication);
      sendWorkerCommand(currentControllerData.value.Id,'restart')
    }

    const actions = [
    {
        name: "Log",
        action: (obj) => {
          getLogs(obj, logCountToShow.value);
        }
      },
      {
        name: "Status",
        color: "green",
        action: (obj) => {
          showActionModal.value = true;
          resetStatusModel();
          poller(obj.Id, obj.LastCommunication);
          sendWorkerCommand(obj.Id,'sendStatus');
        }
      },
      {
        name: "Controllers",
        color: "blue",
        action: (obj) => {
          
          showControllerActionModal.value = true;
          currentControllerData.value = obj;
         //sendWorkerCommand(obj.Id,'runMain')
         
        }
      },
      {
        name: "Clear alarm",
        color: "red",
        if: (obj) => obj.ActiveAlert,
        action: (obj) => {

          api
          .workers
          .clearAlarm(obj.Id)
          .then(response => {
             getWorkerList();
          });
         
        }
      }
     
    ];

    const renderFunctions = computed(()=> {
		return {
			ActiveAlert(obj){
				if(obj.ActiveAlert){
					return '<span><i class="fas fa-exclamation-triangle text-danger"></i></span>';
				}else{
					return '';
				}
			},
		}
	});

	const getLogs = (obj, logCount) => {
		selectedWorkerToShowLog.value = obj;
		
		isLoadingLogModal.value = true;
		logsTable.body = [];
		api.workers.getWorkerLog(obj.Id, logCount).then(res => {
			logsTable.body = res.data
			isLoadingLogModal.value = false;
		});

	}

    return {
      actions,
      workers,
      loaded,
      showActionModal,
      showControllerActionModal,
      workerData,
      moment,
      progressAnimation,
      closeStatusActionModal,
      closeControllerActionModal,
      currentControllerData,
      actionStart,
      actionRestart,
      pollControllerAction,
      showRestartConfirmModal,
      renderFunctions,
	  selectedWorkerToShowLog,
	  logsTable,
	  logRenderFunctions,
	  isLoadingLogModal,
	  logCountToShow,
	  getLogs
      
    };

  }
};
</script>

<style lang="scss" scoped>
.done {
  text-decoration: line-through;
}

.value {
  padding-top: 0.9rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: flex-start;
}

.label-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
}

.label {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #FFFFFF;
  opacity: 0.8;
}

.server-status {
  padding-top: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: baseline;
}

.flex-item {
  margin-top: 5px;
  background: rgba(49, 174, 204, 0.1);
  border-radius: 5px;
  width: 285px;
  height: 44px;
  display: flex;
  padding: 1rem;
  flex-wrap: nowrap;
  align-items: center;
}

progress::-moz-progress-bar { 
  background: blue; 
}

progress::-webkit-progress-value { 
  background: blue; 
}

progress { 
  color: blue; 
}

.margin-6-rem {
  margin-right: 6rem;
}
.label-memmory{
  margin-right: 3.4rem;
}

.label-disk{
  margin-right: 5.7rem;
}

.result-text{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
}

.status-header {
  margin-top: 2rem;
  display: flex;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #D2E3F3;
  }
}

.text-start {
  text-align: start;
}


.progress {
  background: rgba(255,255,255,0.1);
  justify-content: flex-start;
  border-radius: 100px;
  align-items: center;
  position: relative;
  display: flex;
  height: 10px;
}

.progress-value {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.5s;
  box-shadow: 0 10px 40px -10px #fff;
  border-radius: 100px;
  background: linear-gradient(90.01deg, #CDEC0C 1.91%, #3DC95C 100%);
  height: 10px;
  width: 0%;
}

.icon-color {
  color:#31AECC;
}

.icon-color-green {
  color: #3DC95C;
}

.button-normal {
  width: 128px;
  height: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.result-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
}

// @keyframes load {
//   0% { width: 0; }
//   100% { width: 100%; }
// }

</style>
