import { provide, inject} from 'vue';
import { body } from './endpoints';

const ApiSymbol = Symbol();

export const createApi = () => ({
	...body
})

export const provideApi = () => {
	const api = createApi();
	provide(ApiSymbol, api)
}

export const useApi = () => {
	const api = inject(ApiSymbol)
	if (!api) throw new Error("No api provided");
	return api;
}