<template>
    <div class="admin-view">
        <GlobRow>
            <GlobSpinner :isLoading="isLoading"/>
            <GlobCol :medium="12">
                <GlobXpanel :title="$t('administration.support_guides.heading')">
                    
                    <p class="no-data-found" v-if="!data.length">{{ $t('administration.support_guides.no_data_found') }}</p>
                    <div class="help-boxes" v-else>
                        <div class="help-box" v-for="item in data" :key="'help-'+item.Id" @click="selectedItem = item">
                            <h3 class="title">{{ truncateHtml(item.Title, 30) }}</h3>
                            <p class="category">{{ item.Category }}</p>
                            <p class="description" v-html="truncateHtml(item.Description, 150)"></p>
                        </div>
                    </div>
                    
                </GlobXpanel>
            </GlobCol>
        </GlobRow>

        <!-- Show selectedItem in modal -->
        <GlobModal :width="60" 
            :height="700" 
            :title="selectedItem.Title+(selectedItem.Category ? ' - '+selectedItem.Category : '')" 
            class="help-box-modal"
            @close="selectedItem = false" :show="selectedItem ? true : false"  v-if="selectedItem">
            <div class="help-box-modal">
                <p class="description" v-html="selectedItem.Description"></p>
            </div>
            
        </GlobModal>
   </div>
</template>
   
   <script>
   import { onMounted, computed, ref } from "vue";
   import { useApi } from "@/plugins/api";
   
   export default {
    setup() {
        
       const api = useApi();
       const endpoint = computed(() => api.supportGuides);
       
       const data = ref([]);
       const selectedItem = ref(null);
   
       const isLoading = ref(false);
       
       
        onMounted(async() => {
            loadMainList();
        });
   
       const loadMainList = async() => {
           isLoading.value = true;
           endpoint.value.get().then(function(res){
              data.value = res.data;
              isLoading.value = false;
           })
        }

        const truncateHtml = (text, length) => {
            if(text.length > length){
                return text.substring(0, length) + '...';
            }else{
                return text;
            }
        }
   
       return {
           loadMainList,
           data,
           isLoading,
           selectedItem,
           truncateHtml
       };
     }
   };
   </script>

<style lang="scss">
.no-data-found{
    color: $kits-lightGray;
    font-size: 22px;
    opacity: .5;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.help-boxes{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    .help-box{
        background-color: $rpa-moonStoneBlue;
        border-radius: 4px;
        padding: 21px;
        height: 14rem;
        overflow: auto;
        cursor: pointer;
        .title{
            font-size: 24px;
            color: white;
            margin: 0;
        }

        .category{
            font-size: 16px;
            color: white;
            margin: 0;
            min-height: 1rem;
        }

        .description{
            font-size: 18px;
            color: $rpa-black;
            font-weight: 600;

            p{
                color: unset;
            }
        }
    }
}
.help-box-modal{
    
    .description{
        color: white;
        
        p{
            color: unset;
            font-size: 16px;
        }

        h1, h2, h3, h4, h5, h6{
            color: unset;
        }
    }

    .category{
        margin: 0;
        color: white;
        margin-top: 5px;
    }
}

</style>