import { generateId } from '@/services/utils';
import { modules, standard } from './modules';
/* Specify which routes are allowed in the project installation */


/* Filters route or menuItems object based on above preferences */
export const createRoutes = (routes, allowed, isNav = false) => {
	if(!isNav){
		return routes.filter(item => allowed.includes(item.name) || item.isCRUD )
	} else {
		return routes.filter(item => allowed.includes(item.name))
	}
}

const filterNavItems = (items) => items.filter(item => item.nav !== undefined)

const createMenuItem = (item) => {
	return item.children ? {
		...item, 
		id: generateId('nav-item'),
		children: filterNavItems(item.children).map(i => ({...i, id: generateId('nav-child')}))
	} : {
		...item, 
		id: generateId('nav-item'),
	}
}

export const createNav = (routes) => {
	const filteredRoutes = createRoutes(routes, [...standard, ...modules], true);

	return filterNavItems(filteredRoutes).map(item => ({
		...createMenuItem(item)
	}));
}

export {
	modules,
	standard
}
