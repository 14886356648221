import { urlParts } from "./config";
import Cookies from "js-cookie";
import axios from "axios";

axios.defaults.baseURL = process.env.BASE_URL_PHP;
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
axios.defaults.transformRequest = (data, headers) => {
	headers["Authorization"] = Cookies.get("FrontendSessionKey");
	return data;
}
export const php = axios.create({
  baseURL: process.env.BASE_URL_PHP,
  headers: {
		accept: "application/json, multipart/form-data",
		'Content-Type': "application/json"
  },
  transformRequest: (data, headers) => {
    headers["Authorization"] = Cookies.get("FrontendSessionKey");
    return JSON.stringify(data);
  }
});

export const createPhpCrud = (endpoint) => ({
  async get(data = null) {
    const res = await php.get(`${endpoint}`, {params: data});
    return res.data;
  },
  async getById(id, includeDependencies = false) {
    const res = await php.get(`${endpoint}/${id}?includeDependencies=${includeDependencies}`);
    return res.data;
  },
  async update(id, data) {
    const res = await php.put(`${endpoint}/${id}`, data);
    return res.data;
  },
  async create(data) {
    const res = await php.post(`${endpoint}`, data);
    return res.data;
  },
  async delete(id) {
    const res = await php.delete(`${endpoint}/${id}`);
    return res.data;
  }
});

export const body = {
  authenticate: async () => {
    return php.get("/currentuser").then((res) => res.data);
  },
  async login(data, notifications) {
    return new Promise((resolve, reject) => {
      const bodyFormData = new URLSearchParams();
      bodyFormData.append("validateUsername", data.validateUsername);
      bodyFormData.append("validateUserPassword", data.validateUserPassword);
      bodyFormData.append("FrontendSessionKey", data.SessionKey);
      php
        .post("/login", data)
        .then((res) => {
          if (res.data.success) {
            Cookies.set("FrontendSessionKey", res.data.SessionKey)
            resolve(res.data);
          } else {
            notifications.push(
              {
                title: "Wrong credentials",
                msg: "Du har angett felaktiga inloggninsuppgifter"
              },
              "error",
              false,
              false
            );
            reject(res.data);
          }
        })
        .catch((_) => {
          notifications.push(
            {
              title: "Connections problems",
              msg: "Någonting gick fel, kontrollera din internetanslutning."
            },
            "error",
            false,
            false
          );
        });
    });
  },
  async logout() {
    return php.post("/logout").then((res) => {
      Cookies.remove("FrontendSessionKey");
      return res;
    });
  },
  workers: {
    async getWorkerList() {
      const res = await php.get(`/workers`);
      console.log(res.data)
      return res.data;
    },
    async getWorkerLog(id, limit) {
      const res = await php.get(`/workers/${id}/logs`, { params: {limit: limit} });
      return res.data;
    },
    async getWorkerStatus(id, lastCommunicationTimestamp) {
      const params = {lastCommunicationTimestamp};
      const res = await php.get(`/workers/${id}/status`, { params });
      console.log(res.data)
      return res.data;
    },
    async getControllerActionStatus(id, lastCommunicationTimestamp) {
      const params = {lastCommunicationTimestamp};
      const res = await php.get(`/workers/${id}/action/status`, { params });
      console.log(res.data)
      return res.data;
    },
    async sendWorkerCommand(id,command) {
      const res = await php.post(`/workers/${id}/senWorkercommand`, { command: command });
      return res.data;
    },
    async clearAlarm(id) {
      const res = await php.post(`/workers/${id}/clear-alarm`);
      return res.data;
    },
    ...createPhpCrud("/worker"),
  
  },
  supportGuides: {
    async get() {
      const res = await php.get(`/support-guides`);
      return res.data;
    }
  },
  php,
  users: {
    ...createPhpCrud("/user"),
    },
};