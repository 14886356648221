<template>
    <textarea
      class="mas-input"
          :class="fullWidth ? 'full-width' : ''"
      type="text"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows ? rows : 2"
    ></textarea>
  </template>
  
  <script>
  import { props } from "./common";
  export default {
    props: props
  };
  </script>
  