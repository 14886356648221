import { contentTypes } from '../config';
import { reactive, computed } from 'vue';
import { createApi } from '@/plugins/api';

const api = createApi();
/* Admin states */
const createState = () => reactive({
	editing: {},
	dataString: '',
	parentData: null,
	slug: null,
	loaded: false,
	single: false,
	data: {
		body: [],
		head: []
	},
	model: {
		new: null,
		quickEdit: null,
	}
})

let state = createState();

/* Content related functions, old*/

export const getTypeBySlug = (slug) => {
	return contentTypes.find(item => item.config.slug == slug)
}

const setParentData = (data) => {
	state.parentData = data;
}

const getParentData = (data) => {
	return state.parentData;
}

const getFieldName = (slug, key) => {
	return getTypeBySlug(slug).config.head.find(i => i.Alias == key).Value
}

const getDataObject = (slug) => {
	const type = getTypeBySlug(slug);
	const fields = type.config.fieldGroups.map(item => {
		return item.fields
	}).flat();
	return fields.reduce((a,c) => {
		a[c.key] = null;
		return a
	}, {})
}


const getDataObjectByFieldGroup = (group) => {
	return group.fields.reduce((a,c) => {
		a[c.key] = null;
		return a
	}, {})
}

//CRUD 
const getEndpoint = slug => {
	return api[slug]
};

const destroy = () => state = createState();

const getQuickEditFields = (slug) => {
	const type = getTypeBySlug(slug);
	const fields = type.config.fieldGroups.map(item => {
		return item.fields.map(i => ({
			...i,
			name: getFieldName(slug, i.key)
		}))
	}).flat();
	return fields.filter(i => type.config.quickEdit.includes(i.key))
}

const getCreateFields = () => {
	const type = getTypeBySlug(state.slug);
	const fields = type.config.fieldGroups.map(item => {
		return item.fields.map(i => ({
			...i,
			name: getFieldName(state.slug, i.key)
		}))
	}).flat();
	return fields.filter(i => type.config.create.includes(i.key))
}

const getFields = () => {
	const type = getTypeBySlug(state.slug);
	const fields = type.config.fieldGroups.map(item => {
		return item.fields.map(i => ({
			...i,
			name: getFieldName(state.slug, i.key)
		}))
	}).flat();
	return fields;
}

const createModel = (config) => {
	return config.create ? config.create.reduce((acc, curr) => {
		acc[curr] = null;
		return acc;
	}, {}) : false;
}

export const content = {
	getFieldName,
	getTypeBySlug,
	getQuickEditFields,
	getDataObject,
	getDataObjectByFieldGroup,
	setParentData,
	getParentData,
	createModel,
	getCreateFields
}

/* Change validations */
const update = (newData) => {
	state.dataString = JSON.stringify(newData);
}

const check = (newData) => {
	return state.dataString == JSON.stringify(newData)
}

const getDataString = () => {
	return state.dataString;
}

export const validator = {
	check,
	update,
	getDataString
}

/* Main manager creator */

export const createManager = async (slug, id = false, options = {}) => {
	state.slug = slug;
	const config = {
		getData: true,
		...options
	}
	const endpoint = getEndpoint(slug);
	const content = getTypeBySlug(slug);
	const getData = async () => {
		await endpoint.get().then(res => {
			state.data.body = res.data.map(i => {
				return {
					...i,
					searchString: generateSearchString(i)
				}
			});
			state.data.head = content.config.head;
			state.loaded = true;
			return true;
		})
	}

	const generateSearchString = (obj) => {
		return Object.keys(obj).reduce((a,c) => {
			if (obj[c]) {
				return a += obj[c]
			}
			return a;
		}, '').toLowerCase();
	}

	const getSingle = async (id = null) => {
		await endpoint.getById(id).then(res => {
			state.single = res;
			return true;
		})
	}
	if (config.getData) {
		await getData();
		if (id) {
			await getSingle(id);
		}
	}

	const nextItem = computed(() => {
		const index = state.data.body && state.data.body.findIndex((i) => i.Id == state.single.Id);
		return state.data.body && state.data.body[index + 1]
			? state.data.body[index + 1].Id
			: false;
	})

	const prevItem = computed(() => {
		const index = state.data.body && state.data.body.findIndex((i) => i.Id == state.single.Id);
		return state.data.body && state.data.body[index - 1]
			? state.data.body[index - 1].Id
			: false;
	})

	const model = getFields().reduce((a,c) => {
		a[c.key] = null;
		return a;
	}, {});
	

	state.loaded = true;

	return {
		endpoint,
		state,
		destroy,
		content,
		validator,
		quickEditFields: getQuickEditFields(slug),
		hasTemplate: () => content.config.template && content.config.template.component,
		hasCreateTemplate: () => content.config.createTemplate && content.config.createTemplate.component,
		hasCreate: () => content.config.methods.includes('CREATE'),
		getFields,
		model,
		nextItem,
		prevItem,
		getData,
		getSingle
	}
}