import store from '@/store';
import momentTimeZone from 'moment-timezone';

export const dynamicTranslation = (text, params) => {
    Object.keys(params).forEach((key) =>{
        text = text.replace('{'+key+'}', params[key]);
    })
    return text;
}

export const getSavedFileURLFromPath = (filePath) =>{
    if(filePath){
        return process.env.BASE_URL_PHP+'/'+filePath.replace(/public/, 'storage');
    }
    return null;
}

export const convertDateTimeToLocal = (dateTime, returnFormat = null) => {

    if (!dateTime) {
        return dateTime;
    }
    try {
        const user = store.getters.GET_USER;

        const timezone = user.Timezone ? user.Timezone : 'Europe/Stockholm';
        let dateFormat = momentTimeZone(dateTime).creationData().format;
        if (!dateFormat && !returnFormat) {
            if (dateTime.includes('am') || dateTime.includes('pm')) {
                dateFormat = 'hh:mm:ss a';
            } else {
                dateFormat = 'HH:mm:ss';
            }
        } else if (returnFormat) {
            dateFormat = returnFormat;
        }
        const convertedDateTime = momentTimeZone.utc(dateTime, dateFormat).tz(timezone);
        return convertedDateTime.isValid() ? convertedDateTime.format(dateFormat) : dateTime;
    } catch (err) {
        return dateTime;
    }
}

